/* eslint-disable complexity, react/forbid-component-props, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, sonarjs/cognitive-complexity */

import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ORDER } from '../../config/constants/common';
import {
  DEFAULT_DEAL_IMG_WIDTH_TWO_BY_TWO,
  DEFAULT_DEAL_IMG_HEIGHT_TWO_BY_TWO,
} from '../../config/setup/setup';
import { GO_TO_DEAL, SPONSORED_SEARCH, VIEW } from '../../config/text/text';
import { initializeTracking } from '../../helpers/analytics';
import { isCreditecDeal } from '../../helpers/creditec';
import {
  hidePrice,
  showPostage,
  useDealImpresssion,
  getUrlFromDeal,
} from '../../helpers/deals';
import { isWowcherBrand } from '../../helpers/pages';
import {
  sendSponsoredDealImpressions,
  SponsoredImpressionType,
} from '../../helpers/sponsoredSearch';
import useRestoreScroll from '../../helpers/useRestoreScroll';
import ThemeContext from '../../providers/ThemeProvider';
import PrimaryButton from '../_generic/button/PrimaryButton';
import Clamp from '../_generic/utils/Clamp';
import MainDealBoughtCount from './MainDealBoughtCount';
import MainDealDiscount from './MainDealDiscount';
import DealPriceSplat from './shared-components/DealPriceSplat';
import DealSocialCueTop from './shared-components/DealSocialCueTop';
import DealSocialCuesBottom from './shared-components/DealSocialCuesBottom';
import PostageAndPackagingText from './shared-components/PostageAndPackagingText';
import VipExclusiveTag from './shared-components/VipExclusiveTag';
import ReserveButton from './shared-components/buy-buttons/ReserveButton';
import CarouselBlock from './shared-components/carousel/CarouselBlock';
import DealCardWrapper from './shared-components/dealCardWrapper';
import EmptyDeal from './shared-components/emptyDeal';

const WishlistIcon = dynamic(() => import('./shared-components/WishlistIcon'), {
  ssr: false,
});

const TwoByTwoDealCard = ({
  deal = null,
  extraclasses = '',
  isHideSplat = false,
}) => {
  const router = useRouter();
  const theme = useContext(ThemeContext);
  const [
    ssr,
    isVipUser,
    mainDeal,
    trackingDeals,
    secondaryDealsArray,
  ] = useSelector((state) => [
    state.deals.ssr,
    state?.user?.userprofile?.vipUser,
    state?.deals?.mainDeal,
    state?.tracking?.trackingDeals,
    state?.deals?.secondaryDealsArray,
  ]);
  const location = useSelector((state) => state.locations.location.shortName);
  const componentRef = useRef(null);
  useDealImpresssion({ componentRef, dealId: deal.id, location });
  const [, setScrollData] = useRestoreScroll();
  const showVipExclusiveTag = isVipUser && deal?.display?.vipExclusive;

  if (!deal || Object.keys(deal).length === 0) return <EmptyDeal />;

  const dealHeadline =
    isVipUser && deal?.vipHeadline ? deal?.vipHeadline : deal?.headline;
  const headLineAux = deal.headline ? hidePrice(dealHeadline, ssr) : '';

  const dealUrl = getUrlFromDeal({
    deal,
    location,
    originPath: router.asPath,
  });

  const showPercentageInSplat = false;

  const onClick = () => {
    if (router.asPath.includes('deals')) {
      initializeTracking(deal, [
        mainDeal,
        ...secondaryDealsArray,
        ...trackingDeals,
      ]);
    } else {
      initializeTracking(deal, [...trackingDeals]);
    }

    if (deal.isSponsored) {
      sendSponsoredDealImpressions(SponsoredImpressionType.click, [deal]);
    }

    setScrollData({
      dealId: deal.id,
      page: deal?.page,
      url: window.location.href,
    });
  };

  const onClickReserveButton = () => {
    window.location.href = dealUrl;

    setScrollData({
      dealId: deal.id,
      page: deal?.page,
      url: window.location.href,
    });
  };

  const hasDiscount =
    (deal.display.discountAmount && deal.discount > 0) ||
    (deal.display.discount && deal.discountPercentage > 0);
  const hasBoughtCount = deal.display.quantityRemaining || deal.display.bought;

  const hasCueOnSeperateLine = hasDiscount && hasBoughtCount;
  const hasPostage = showPostage(deal, showPercentageInSplat, true);

  return (
    <>
      <div
        className={`two-by-two-deal ${extraclasses}`}
        data-testid={`deal_${deal.id}`}
        id={`deal_${deal.id}`}
        onClick={onClick}
        ref={componentRef}
      >
        <DealCardWrapper>
          <div className="image-container">
            <WishlistIcon dealId={deal.id} />
            <ReserveButton
              deal={deal}
              disableBasket
              extraClasses="reserve-button size-small"
              onClickAction={onClickReserveButton}
            />
            <CarouselBlock
              deal={deal}
              height={DEFAULT_DEAL_IMG_HEIGHT_TWO_BY_TWO}
              href={dealUrl}
              showImageOverlays
              showScrim
              single
              width={DEFAULT_DEAL_IMG_WIDTH_TWO_BY_TWO}
            />
            <DealPriceSplat
              className="xl"
              deal={deal}
              discount={showPercentageInSplat}
              isHideIfNoDiscount={false}
              isHideSplat={isHideSplat}
            />
            {showVipExclusiveTag && (
              <VipExclusiveTag className="tag-before-splat--lg" />
            )}
            {deal.isSponsored && (
              <p className="sponsored">{SPONSORED_SEARCH}</p>
            )}
          </div>
          <Link as={dealUrl ?? ''} href="/deal/[...slug]">
            <a className="deal-info">
              <div className="deal-title">
                <Clamp lines={2}>
                  <h3 className="headline">{headLineAux}</h3>
                </Clamp>
                <PostageAndPackagingText
                  className="mobile-invert"
                  deal={deal}
                  discount={showPercentageInSplat}
                  show={hasPostage}
                />
                {!isCreditecDeal(deal) && (
                  <DealSocialCuesBottom forcePrice socialCuesData={deal} />
                )}
              </div>
              <div className="deal-footer">
                <div className="social-cues">
                  <div className="top-line-cues">
                    <MainDealBoughtCount deal={deal} />
                    {!isHideSplat && <MainDealDiscount deal={deal} />}
                  </div>
                  <div className="bottom-line-cues">
                    <DealSocialCueTop
                      order={ORDER.even}
                      socialCueClaim={deal?.socialCueClaim}
                      socialCuesAvailability={deal.totalRemaining || 0}
                      socialCuesBought={deal.totalBought || 0}
                    />
                  </div>
                </div>
                <PrimaryButton
                  ariaLabel={GO_TO_DEAL.replace('##DEAL##', deal.headline)}
                >
                  {VIEW}
                </PrimaryButton>
              </div>
            </a>
          </Link>
        </DealCardWrapper>
      </div>
      <style jsx>{`
        .two-by-two-deal {
          margin: 0 0 20px 0;
          width: 49%;
        }

        .two-by-two-deal :global(.social-cues-container__main-image__bottom),
        .two-by-two-deal :global(.social-cues-container__main-image__top) {
          position: static;
          padding: 0;
        }

        .image-container {
          position: relative;
        }

        .image-container :global(.primary-button.reserve-button) {
          position: absolute;
          right: 5px;
          border-radius: 5px;
          padding: 5px;
          position: absolute;
          text-transform: ${isWowcherBrand() ? 'capitalize' : 'lowercase'};
          top: 36px;
          height: auto;
          width: auto;
          display: block;
        }

        .deal-info {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          width: 100%;
          padding: 0 10px 10px;
          margin-top: 7px;
          text-decoration: none;
          cursor: pointer;
        }

        .deal-title {
          display: flex;
          flex-direction: column;
          justify-content: center;
          min-height: 60px;
        }

        .headline {
          color: ${theme.colors.dealtext};
          margin: 0;
        }

        .two-by-two-deal :global(.card) {
          width: 100%;
          height: 100%;
        }

        .two-by-two-deal :global(.primary-button) {
          min-width: 136px;
          width: 136px;
          height: 44px;
        }

        .deal-footer {
          display: flex;
          align-items: center;
          padding-top: 10px;
          flex-grow: 1;
        }

        .social-cues {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          flex-grow: 1;
        }

        .top-line-cues {
          display: flex;
          align-items: center;
        }

        .bottom-line-cues {
          width: ${hasCueOnSeperateLine ? '100%' : 'initial'};
        }

        .two-by-two-deal :global(.deal-main-deal__discount) {
          display: none;
          margin: 0 16px 0 0;
          line-height: 26px;
        }

        .two-by-two-deal :global(.deal-main-deal__new-today),
        .two-by-two-deal :global(.deal-main-deal__bought) {
          display: none;
        }

        .two-by-two-deal :global(.social-cues-container__main-image__top) {
          width: auto;
        }

        .sponsored {
          position: absolute;
          top: 0;
          right: 0;
          padding: 6px;
          color: #777777;
          font-size: 10px;
          line-height: 1em;
        }

        a {
          text-decoration: none;
        }

        .two-by-two-deal :global(.wishlist-icon .message-container) {
          width: 200px;
        }

        @media (min-width: ${theme.breakpoints.lgUp}) {
          .headline {
            font-size: 18px;
          }
          .two-by-two-deal :global(.deal-main-deal__discount) {
            display: block;
          }
          .bottom-line-cues {
            display: block;
          }
        }

        @media (min-width: ${theme.breakpoints.smUp}) {
          .two-by-two-deal :global(.social-cues-container__main-image__bottom) {
            display: none;
          }
          .two-by-two-deal :global(.deal-main-deal__new-today),
          .two-by-two-deal :global(.deal-main-deal__bought) {
            display: block;
            margin-right: 16px;
            white-space: nowrap;
            width: auto;
          }
          .two-by-two-deal :global(.social-cue__item) {
            height: auto !important;
            margin-right: 10px !important;
            margin-left: 0 !important;
          }
        }

        @media (max-width: ${theme.breakpoints.smDown}) {
          .two-by-two-deal {
            width: 100%;
            height: auto;
            margin: 5px 0 0 0;
          }

          .deal-info {
            position: absolute;
            bottom: 0px;
            left: 0;
            width: 100%;
            padding-bottom: 36px;
            padding-left: 10px;
            width: calc(100% - 90px);
            height: 100%;
            justify-content: flex-end;
            cursor: pointer;
          }

          .deal-title {
            min-height: 0;
          }

          .deal-footer {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
          }

          .headline {
            color: ${theme.colors.dealmobiletitle};
            font-size: 16px;
            line-height: normal;
            font-weight: bold;
          }

          .two-by-two-deal :global(.card) {
            width: 100%;
            height: auto;
            margin-bottom: 0;
          }

          .two-by-two-deal :global(.primary-button) {
            display: none;
          }

          .two-by-two-deal :global(.social-cues-container__main-image__top) {
            position: absolute;
            width: calc(100% + 90px);
          }

          .two-by-two-deal :global(.bought-count) {
            position: absolute;
            padding: 0;
            margin: 0;
            bottom: 10px;
            background-color: transparent;
            color: white;
          }
          .two-by-two-deal :global(.wishlist-icon .message-container) {
            width: 60px;
          }
          .two-by-two-deal :global(.primary-button.reserve-button) {
            top: 55px;
          }
        }
      `}</style>
    </>
  );
};

TwoByTwoDealCard.propTypes = {
  deal: PropTypes.object,
  extraclasses: PropTypes.string,
  isHideSplat: PropTypes.bool,
};

export default TwoByTwoDealCard;
